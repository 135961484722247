import Figure from './figure'


const serializers = {
  types: {
    figure: Figure
    
  }
}

export default serializers
